import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { DateTime } from 'luxon';
import { DateFormatter, SpecialLabel } from '@billon/ui';
import {
  AvailableLanguagesEnum,
  KYCBankAccountTypesEnum,
} from '@billon/shared';
import {
  TransactionStatusesEnum,
  TransactionTypesEnum,
  UserTypesEnum,
} from 'corporate-account-shared';

import {
  DateRangesEnum,
  TransactionCreatedFromTypesEnum,
} from './utils/constants';

export const availableLanguagesFormatter = (
  language: AvailableLanguagesEnum,
  formatMessage: (descriptor: MessageDescriptor) => string,
) => {
  switch (language) {
    default:
    case AvailableLanguagesEnum.PL:
      return formatMessage({ id: 'polish', defaultMessage: 'polish' });
    case AvailableLanguagesEnum.EN:
      return formatMessage({ id: 'english', defaultMessage: 'english' });
  }
};

export const transactionTypeFormatter = (
  transactionType: TransactionTypesEnum,
  formatMessage: (descriptor: MessageDescriptor) => string,
): string => {
  switch (transactionType) {
    default:
    case TransactionTypesEnum.BANK_TRANSFER:
      return formatMessage({
        id: 'Bank transfer',
        defaultMessage: 'Bank transfer',
      });
    case TransactionTypesEnum.PEER_OUTGOING:
    case TransactionTypesEnum.PEER_INCOMING:
      return formatMessage({
        id: 'P2P transfer',
        defaultMessage: 'P2P transfer',
      });
    case TransactionTypesEnum.REDEEM:
      return formatMessage({
        id: 'E-Money Redemption',
        defaultMessage: 'E-Money Redemption',
      });
    case TransactionTypesEnum.PAYOUT:
      return formatMessage({
        id: 'Payout',
        defaultMessage: 'Payout',
      });
    case TransactionTypesEnum.PAYIN:
      return formatMessage({
        id: 'Payin',
        defaultMessage: 'Payin',
      });
    case TransactionTypesEnum.TOP_UP:
      return formatMessage({
        id: 'Purchase of E-Money',
        defaultMessage: 'Purchase of E-Money',
      });
    case TransactionTypesEnum.COMMISSION:
      return formatMessage({
        id: 'Commision',
        defaultMessage: 'Commision',
      });
  }
};

export const transactionStatusFormatter = (
  value: TransactionStatusesEnum,
  formatMessage: (descriptor: MessageDescriptor) => string,
  override = true,
) => {
  const inProgressStatus = override ? 'Completed successfully' : 'Processing';
  const finishedErrStatus = override
    ? 'Completed successfully'
    : 'Completed unsuccessfully';
  switch (value) {
    default:
    case TransactionStatusesEnum.SCHEDULED:
      return formatMessage({ id: 'Pending', defaultMessage: 'Pending' });
    case TransactionStatusesEnum.IN_PROGRESS:
      return formatMessage({
        id: inProgressStatus,
        defaultMessage: inProgressStatus,
      });
    case TransactionStatusesEnum.FINISHED_OK:
      return formatMessage({
        id: 'Completed successfully',
        defaultMessage: 'Completed successfully',
      });
    case TransactionStatusesEnum.FINISHED_ERR:
      return formatMessage({
        id: finishedErrStatus,
        defaultMessage: finishedErrStatus,
      });
    case TransactionStatusesEnum.READY_TO_COLLECT:
      return formatMessage({ id: 'To Collect', defaultMessage: 'To Collect' });
    case TransactionStatusesEnum.SUSPENDED:
      return formatMessage({ id: 'Suspended', defaultMessage: 'Suspended' });
    case TransactionStatusesEnum.CANCELLED:
      return formatMessage({ id: 'Cancelled', defaultMessage: 'Cancelled' });
    case TransactionStatusesEnum.UNAUTHORIZED:
      return formatMessage({ id: 'To approve', defaultMessage: 'To approve' });
    case TransactionStatusesEnum.SCHEDULED:
      return formatMessage({ id: 'Scheduled', defaultMessage: 'Scheduled' });
    case TransactionStatusesEnum.EXPIRED:
      return formatMessage({ id: 'Expired', defaultMessage: 'Expired' });
    case TransactionStatusesEnum.PAID:
      return formatMessage({ id: 'Paid', defaultMessage: 'Paid' });
    case TransactionStatusesEnum.NEW:
      return formatMessage({ id: 'New', defaultMessage: 'New' });
    case TransactionStatusesEnum.FAILED:
      return formatMessage({ id: 'Failed', defaultMessage: 'Failed' });
  }
};

export const statusFormatter = (
  value: TransactionStatusesEnum,
  formatMessage: (descriptor: MessageDescriptor) => string,
  override = true,
) => {
  let color:
    | 'primary'
    | 'secondary'
    | 'primaryLight'
    | 'secondaryLight'
    | 'success'
    | 'danger'
    | 'grayLightest'
    | 'grayLighter'
    | 'grayLight'
    | 'gray'
    | 'grayDark'
    | 'grayDarker'
    | 'grayDarkest' = 'grayLighter';

  if (
    [
      TransactionStatusesEnum.FINISHED_OK,
      TransactionStatusesEnum.FINISHED_ERR,
      TransactionStatusesEnum.IN_PROGRESS,
    ].includes(value)
  ) {
    color = 'success';
  }

  if (value === TransactionStatusesEnum.FAILED) {
    color = 'danger';
  }

  return (
    <SpecialLabel color={color}>
      {transactionStatusFormatter(value, formatMessage, override)}
    </SpecialLabel>
  );
};

export const userTypeFormatter = (
  userType: UserTypesEnum,
  formatMessage: (descriptor: MessageDescriptor) => string,
): string => {
  switch (userType) {
    default:
    case UserTypesEnum.USER:
      return formatMessage({
        id: 'Representative',
        defaultMessage: 'Representative',
      });
    case UserTypesEnum.ADMIN:
      return formatMessage({ id: 'Admin', defaultMessage: 'Admin' });
    case UserTypesEnum.SUPER_ADMIN:
      return formatMessage({
        id: 'Super Admin',
        defaultMessage: 'Super Admin',
      });
    case UserTypesEnum.CONSUMER:
      return formatMessage({
        id: 'Consumer',
        defaultMessage: 'Consumer',
      });
  }
};

export const formSelectOptionsFormatter = (
  options: Array<any>,
  labelFormatter?: (value: any) => string,
) =>
  options
    ? options.map((o: string) => ({
        value: o,
        label: labelFormatter ? labelFormatter(o) : o,
      }))
    : [];

export const valuesToObjectFormatter = (
  data?: string[],
): Record<string, boolean | null> =>
  (data || []).reduce((obj: Record<string, boolean>, value: string) => {
    obj[value] = true;
    return obj;
  }, {});

export const valuesToArrayFormatter = (
  data: Record<string | number, boolean>,
) =>
  Object.keys(data).reduce<(string | number)[]>((arr, value) => {
    if (data[value] === true) {
      arr.push(value);
    }
    return arr;
  }, []);

export const activationFormatter = (value: boolean) =>
  value ? (
    <SpecialLabel>
      <FormattedMessage id="Active" defaultMessage="Active" />
    </SpecialLabel>
  ) : (
    <SpecialLabel color="danger">
      <FormattedMessage id="Inactive" defaultMessage="Inactive" />
    </SpecialLabel>
  );

export const verificationtionFormatter = (value: boolean) =>
  value ? (
    <SpecialLabel>
      <FormattedMessage id="Verified" defaultMessage="Verified" />
    </SpecialLabel>
  ) : (
    <SpecialLabel color="danger">
      <FormattedMessage id="Unverified" defaultMessage="Unverified" />
    </SpecialLabel>
  );

export const successFailedFormatter = (value: boolean) =>
  value ? (
    <SpecialLabel>
      <FormattedMessage id="Success" defaultMessage="Success" />
    </SpecialLabel>
  ) : (
    <SpecialLabel color="danger">
      <FormattedMessage id="Failed" defaultMessage="Failed" />
    </SpecialLabel>
  );

export const dateRangeTypeFormatter = (
  value: DateRangesEnum,
  formatMessage: (descriptor: MessageDescriptor) => string,
) => {
  return {
    [DateRangesEnum.LAST_7_DAYS]: formatMessage({
      id: 'Last 7 days',
      defaultMessage: 'Last 7 days',
    }),
    [DateRangesEnum.LAST_30_DAYS]: formatMessage({
      id: 'Last 30 days',
      defaultMessage: 'Last 30 days',
    }),
    [DateRangesEnum.THIS_MONTH]: formatMessage({
      id: 'This month',
      defaultMessage: 'This month',
    }),
    [DateRangesEnum.LAST_MONTH]: formatMessage({
      id: 'Last month',
      defaultMessage: 'Last month',
    }),
    [DateRangesEnum.ANOTHER_PERIOD]: formatMessage({
      id: 'Another period',
      defaultMessage: 'Another period',
    }),
    [DateRangesEnum.ALL]: formatMessage({
      id: 'All',
      defaultMessage: 'All',
    }),
  }[value];
};

export const transformDateRangeFromTo = (range: DateRangesEnum) => {
  switch (range) {
    case DateRangesEnum.LAST_7_DAYS:
      return {
        from: DateTime.utc().minus({ days: 7 }).startOf('day').toJSDate(),
      };
    case DateRangesEnum.LAST_30_DAYS:
      return {
        from: DateTime.utc().minus({ days: 30 }).startOf('day').toJSDate(),
      };
    case DateRangesEnum.THIS_MONTH:
      return {
        from: DateTime.utc().startOf('month').toJSDate(),
      };
    case DateRangesEnum.LAST_MONTH:
      return {
        from: DateTime.utc().minus({ month: 1 }).startOf('month').toJSDate(),
        to: DateTime.utc().minus({ month: 1 }).endOf('month').toJSDate(),
      };
    case DateRangesEnum.ALL:
      return undefined;
  }
};

export const boolFormatter = (
  value: boolean,
  formatMessage: (descriptor: MessageDescriptor) => string,
) =>
  formatMessage({
    id: value ? 'Yes' : 'No',
    defaultMessage: value ? 'Yes' : 'No',
  });

export const transactionCreatedFromTypeFormatter = (
  value: TransactionCreatedFromTypesEnum,
  formatMessage: (descriptor: MessageDescriptor) => string,
) => {
  return {
    [TransactionCreatedFromTypesEnum.MANUAL]: formatMessage({
      id: 'Manually',
      defaultMessage: 'Manually',
    }),
    [TransactionCreatedFromTypesEnum.FROM_FILE]: formatMessage({
      id: 'From file',
      defaultMessage: 'From file',
    }),
    [TransactionCreatedFromTypesEnum.API]: formatMessage({
      id: 'API',
      defaultMessage: 'API',
    }),
  }[value];
};

export const KYCBankAccountTypeFormatter = (
  accountType: KYCBankAccountTypesEnum,
  formatMessage: (descriptor: MessageDescriptor) => string,
) => {
  switch (accountType) {
    default:
    case KYCBankAccountTypesEnum.INDIVIDUAL:
      return formatMessage({ id: 'Individual', defaultMessage: 'Individual' });
    case KYCBankAccountTypesEnum.COMPANY:
      return formatMessage({ id: 'Company', defaultMessage: 'Company' });
  }
};

export const dateChipFormatter = (label, value, withTime = false) => (
  <>
    <FormattedMessage id={label} defaultMessage={label} />:{' '}
    <DateFormatter value={value} withTime={withTime} />
  </>
);

export const baseChipFormatter = (label, value) => (
  <>
    <FormattedMessage id={label} defaultMessage={label} />: {value}
  </>
);
