import { SelectOptionProps } from '@billon/ui';

import { useCustomersRequest } from './useCustomersRequest';

export const useCustomerOptions = (isSuperAdmin?: boolean) => {
  const { data: customers, isFetching } = useCustomersRequest(isSuperAdmin);

  const customersOptions: SelectOptionProps[] = [];

  if (!isFetching && customers) {
    customers.rows.map((customer) => {
      customersOptions.push({
        label: customer.name,
        value: customer.id,
      });
    });
  }

  return { customersOptions, isFetching };
};
