import { FormattedMessage } from 'react-intl';
import { CurrencyFormatter, Icon } from '@billon/ui';
import { milibillonToCurrencyValue } from '@billon/utils';

import { Col, Row } from 'react-awesome-styled-grid';
import styled from 'styled-components';

// Types
import { AccountInterface } from 'pages/Accounts/types';

const StyledRow = styled(Row)`
  min-width: 100%;
`;

const StyledAccountBalanceInfo = styled.div`
  display: flex;
  align-items: flex-end;
  min-width: 100%;
  margin-bottom: 1rem;
`;

const StyledAccountBalanceLabel = styled.label`
  font-weight: ${({ theme }) => theme.fontWeightBase};
  font-size: ${({ theme }) => theme.fontSizeSm};
  margin-bottom: 0;
`;

const StyledAccountBalanceValueHolder = styled.div`
  display: flex;
`;

const StyledAccountBalanceValue = styled.div`
  line-height: 1.25rem;
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.fontWeightBold};
`;

const StyledAccountBalanceLocked = styled.div`
  color: ${({ theme }) => theme.palette.grayLight};
  display: flex;
  gap: 0.25rem;
  align-items: center;
  line-height: 1.25rem;
  margin: 0 0.5rem;
  font-size: ${({ theme }) => theme.fontSizeSm};
`;

export interface AccountAvailableFundsProps {
  account: AccountInterface;
  withLockedBalance?: boolean;
}

export const AccountAvailableFunds = ({
  account,
  withLockedBalance = true,
}: AccountAvailableFundsProps) => (
  <StyledRow>
    <Col md={4} lg={3.5} align="flex-start" justify="center">
      <StyledAccountBalanceInfo>
        <div>
          <StyledAccountBalanceLabel>
            <FormattedMessage
              id="Total balance"
              defaultMessage="Total balance"
            />
            :
          </StyledAccountBalanceLabel>
          <StyledAccountBalanceValue>
            <CurrencyFormatter
              value={milibillonToCurrencyValue(
                account.lockedBalance + account.balance ?? 0,
              )}
              currency={account.currency}
            />
          </StyledAccountBalanceValue>
        </div>
      </StyledAccountBalanceInfo>
    </Col>
    <Col md={4} lg={8.5} align="flex-start" justify="center">
      <StyledAccountBalanceInfo>
        <div>
          <StyledAccountBalanceLabel>
            <FormattedMessage
              id="Available funds"
              defaultMessage="Available funds"
            />
            :
          </StyledAccountBalanceLabel>
          <StyledAccountBalanceValueHolder>
            <div>
              <StyledAccountBalanceValue>
                <CurrencyFormatter
                  value={milibillonToCurrencyValue(account.balance ?? 0)}
                  currency={account.currency}
                />
              </StyledAccountBalanceValue>
            </div>
            {withLockedBalance && (
              <div>
                <StyledAccountBalanceLocked>
                  <Icon name="lock" />
                  <CurrencyFormatter
                    value={milibillonToCurrencyValue(
                      account.lockedBalance ?? 0,
                    )}
                    currency={account.currency}
                  />
                </StyledAccountBalanceLocked>
              </div>
            )}
          </StyledAccountBalanceValueHolder>
        </div>
      </StyledAccountBalanceInfo>
    </Col>
  </StyledRow>
);
