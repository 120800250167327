import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const StyledListElement = styled.li`
  margin-bottom: 0.5rem;
`;

const StyledLabel = styled.label`
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeightBase};
  font-size: 0.75rem;
  margin: 0;
`;

const StyledValue = styled.div`
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeightBold};
`;

export interface DetailsListElementProps {
  label: string;
  value: any;
}

export const DetailsListElement = ({ label, value }) => (
  <StyledListElement>
    <StyledLabel>
      <FormattedMessage id={label} defaultMessage={label} />
    </StyledLabel>
    <StyledValue>{value}</StyledValue>
  </StyledListElement>
);
