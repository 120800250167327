import { StandardFormField } from '@billon/ui';
import { useIntl } from 'react-intl';
import { FieldPath } from 'react-hook-form';

// Types
import { TransactionFieldProps } from '../types';

export function Reference<T>({
  errors,
  control,
  isLoading,
  disabled = false,
  name = 'title',
  namePrefix = '',
  required,
}: TransactionFieldProps<T>) {
  const { formatMessage } = useIntl();
  return (
    <StandardFormField<T>
      control={control}
      isLoading={isLoading}
      disabled={disabled}
      errors={errors}
      name={`${namePrefix}${name}` as FieldPath<T>}
      placeholder={formatMessage({
        id: 'Place reference here',
        defaultMessage: 'Place reference here',
      })}
      label={formatMessage({
        id: 'Reference',
        defaultMessage: 'Reference',
      })}
      required={required}
    />
  );
}
