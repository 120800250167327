import { Control, FieldErrors, FieldName } from 'react-hook-form';
import styled from 'styled-components';
import {
  CountryCodesEnum,
  CurrencyCodesEnum,
  TransactionDetailsFieldsEnum,
} from '@billon/utils';
import { Alert, Loader as BaseLoader } from '@billon/ui';

import {
  AccountNumber,
  BIC,
  IBAN,
  RoutingCode,
  SortCode,
} from 'components/TransactionFields';
import { usePaymentRequiredFieldsRequest } from 'hooks';
import { UseQueryOptions } from 'react-query';
import { FormattedMessage } from 'react-intl';

export interface TransactionDetailsProps<T> {
  countryCode?: CountryCodesEnum;
  currency?: CurrencyCodesEnum;
  options: UseQueryOptions<TransactionDetailsFieldsEnum[]>;
  control: Control<T>;
  errors: FieldErrors<T>;
  disabledFields?: FieldName<T>[];
  isLoading: boolean;
  namePrefix?: string;
}

const Loader = styled(BaseLoader)`
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
`;

export function TransactionDetails<T>({
  disabledFields,
  options = {},
  ...props
}: TransactionDetailsProps<T>) {
  const {
    isFetching,
    data: fields,
    isError,
  } = usePaymentRequiredFieldsRequest(
    props.countryCode,
    props.currency,
    options,
  );

  if (isFetching) {
    return <Loader width={48} color="primaryLight" />;
  }

  if (isError) {
    return (
      <Alert color="error">
        <FormattedMessage
          id="It is not possible to complete the transfer due to the lack of necessary details. Please contact us at: {email}"
          defaultMessage="It is not possible to complete the transfer due to the lack of necessary details. Please contact us at: {email}"
          values={{
            email: (
              <a href="mailto:kontakt@billonsolutions.com">
                kontakt@billonsolutions.com
              </a>
            ),
          }}
        />
      </Alert>
    );
  }

  return (
    <>
      {(fields || []).map((field) => {
        const sharedProps = {
          ...props,
          disabled: disabledFields?.includes(field as FieldName<T>),
          key: field,
        };

        switch (field) {
          case TransactionDetailsFieldsEnum.IBAN:
            return <IBAN {...sharedProps} required />;

          case TransactionDetailsFieldsEnum.BIC:
            return <BIC {...sharedProps} required />;

          case TransactionDetailsFieldsEnum.SORT_CODE:
            return <SortCode {...sharedProps} required />;

          case TransactionDetailsFieldsEnum.ACCOUNT_NUMBER:
            return <AccountNumber {...sharedProps} required />;

          case TransactionDetailsFieldsEnum.ROUTING_CODE:
            return <RoutingCode {...sharedProps} required />;
        }
      })}
    </>
  );
}
