import { FormErrorsEnum as BaseFormErrorsEnum } from '@billon/ui';
import { TransactionStatusesEnum } from 'corporate-account-shared';

export enum SettingsEnum {
  AVAILABLE_CURRENCIES = 'AVAILABLE_CURRENCIES',
  AVAILABLE_COUNTRIES = 'AVAILABLE_COUNTRIES',
}

export const TransactionsStatusesInHistory = [
  TransactionStatusesEnum.READY_TO_COLLECT,
  TransactionStatusesEnum.SCHEDULED,
  TransactionStatusesEnum.FINISHED_ERR,
  TransactionStatusesEnum.FINISHED_OK,
  TransactionStatusesEnum.IN_PROGRESS,
  TransactionStatusesEnum.PAID,
];

export enum CorporateAccountFormErrorsEnum {
  INVALID_RECIPIENT_NAME = 'Recipient name must equal or longer than 5 characters',
  INVALID_BENEFICIARY_NAME = 'Beneficiary name must equal or longer than 5 characters',
  INVALID_MIN_VALUE_OF_FIELD = 'The minimum number of characters to enter in this field is {quantity}',
  INVALID_MAX_VALUE_OF_FIELD = 'The maximum number of characters to enter in this field is {quantity}',
  INVALID_MAX_VALUE_OF_BENEFICIARY_NAME = 'The maximum number of characters for the data: first name and last name is {quantity}',
  BUDGET_TOO_LOW = 'Budget too low. Budget cannot be lower than already used budget {usedBudget}',
  INVALID_REMINDER_AMOUND_OF_DAYS = 'Amount of days should be greater than 0',
  ONE_OF_OPTIONS_REQUIRED = 'One of the options above required',
  INVALID_TRANSACTION_FROM_AMOUNT = 'Min transaction amount must be less than or equal to max transaction amount',
  INVALID_TRANSACTION_TO_AMOUNT = 'Max transaction amount must be greater than or equal to min transaction amount',
  INVALID_PAYOUT_START_DATE = 'Payout availability start date must be greater than or equal to {date}',
  INVALID_PAYOUT_END_DATE = 'Payout availability end date must be greater than or equal to {date}',
  INVALID_SENDER_NAME = 'SMS sender name cannot be longer than 11 characters long and contains at least one letter. Allowed characters: small and big letters (without diacritics), digits (no more than 3 digits next to each other), spaces and special characters (.&@-+_!%)',
  TWO_DIGITS_REQUIRED = '{fieldName} must contains two digits',
  PAYOUT_SELECTION_REQUIRED = 'Select at least one payout to collect!',
  INVALID_HUMAN_TASK_ID = 'Human Task Id hash can contain a maximum of 4 characters, and only numbers, small and capital letters!',
}

export const FormErrorsEnum = {
  ...CorporateAccountFormErrorsEnum,
  ...BaseFormErrorsEnum,
  INVALID_FAVICON_DIMENSIONS:
    'Favicon dimensions are invalid. Required 16x16 px or 32x32 px.',
  WEB_TITLE_GREATER_THAN_60: 'Web title cannot be longer that 60 chcaracters',
  INVALID_URL: 'Url is invalid',
};

export enum DateRangesEnum {
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  THIS_MONTH = 'THIS_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  ANOTHER_PERIOD = 'ANOTHER_PERIOD',
  ALL = 'ALL',
}

export enum TransactionCreatedFromTypesEnum {
  MANUAL = 'MANUAL',
  FROM_FILE = 'FROM_FILE',
  API = 'API',
}

export enum RepresentativeTypesEnum {
  GENERAL = 'GENERAL',
  SPECIAL = 'SPECIAL',
}

export enum QueryKeysEnum {
  COUNTRIES_REQUEST = 'COUNTRIES_REQUEST',

  SETTINGS_REQUEST = 'SETTINGS_REQUEST',

  PAYMENT_ROUTES_REQUIRED_FIELDS = 'PAYMENT_ROUTES_REQUIRED_FIELDS',
  PAYMENT_ROUTES_COUNTRIES = 'PAYMENT_ROUTES_COUNTRIES',

  CUSTOMERS_REQUEST = 'CUSTOMERS_REQUEST',
  CUSTOMER_REQUEST = 'CUSTOMER_REQUEST',
  CUSTOMER_SETTINGS_REQUEST = 'CUSTOMER_SETTINGS_REQUEST',
  CUSTOMER_AVAILABLE_MODULES_REQUEST = 'CUSTOMER_AVAILABLE_MODULES_REQUEST',
  CUSTOMER_REDEEM_PAYMENT_DETAILS = 'CUSTOMER_REDEEM_PAYMENT_DETAILS',

  ACCOUNTS_REQUEST = 'ACCOUNTS_REQUEST',
  ACCOUNT_REQUEST = 'ACCOUNT_REQUEST',

  TRANSACTIONS_REQUEST = 'TRANSACTIONS_REQUEST',

  BENEFICIARIES_REQUEST = 'BENEFICIARIES_REQUEST',
  BENEFICIARY_REQUEST = 'BENEFICIARY_REQUEST',

  PEER_BENEFICIARIES_REQUEST = 'PEER_BENEFICIARIES_REQUEST',
  PEER_BENEFICIARY_REQUEST = 'PEER_BENEFICIARY_REQUEST',

  PAYOUT_COLLECTION_REQUEST = 'PAYOUT_COLLECTION_REQUEST',
  PAYOUT_COLLECTION_TEMPLATE_REQUEST = 'PAYOUT_COLLECTION_TEMPLATE_REQUEST',
  PAYOUT_COLLECTION_URLS_REQUEST = 'PAYOUT_COLLECTION_URLS_REQUEST',
  PAYOUT_COLLECTION_SETTINGS_REQUEST = 'PAYOUT_COLLECTION_SETTINGS_REQUEST',
  PAYOUT_COLLECTION_BANK_ACCOUNTS_REQUEST = 'PAYOUT_COLLECTION_BANK_ACCOUNTS_REQUEST',

  USERS_REQUEST = 'USERS_REQUEST',
  USER_REQUEST = 'USER_REQUEST',

  CONSUMERS_REQUEST = 'CONSUMERS_REQUEST',
  CONSUMER_REQUEST = 'CONSUMER_REQUEST',

  COMMISSIONS_REQUEST = 'COMMISSIONS_REQUEST',
  COMMISSION_REQUEST = 'COMMISSION_REQUEST',

  PAYINS_REQUEST = 'PAYINS_REQUEST',
  BAR_CODES_REQUEST = 'BAR_CODES_REQUEST',
  BAR_CODE_REQUEST = 'BAR_CODE_REQUEST',
  PAYERS_REQUEST = 'PAYERS_REQUEST',
  PAYER_REQUEST = 'PAYER_REQUEST',

  PAYOUTS_REQUEST = 'PAYOUTS_REQUEST',
  PAYOUT_REQUEST = 'PAYOUT_REQUEST',
  RECIPIENTS_REQUEST = 'RECIPIENTS_REQUEST',
  RECIPIENT_REQUEST = 'RECIPIENT_REQUEST',
  PROJECTS_REQUEST = 'PROJECTS_REQUEST',
  PROJECT_REQUEST = 'PROJECT_REQUEST',
  DOCUMENT_REQUEST = 'DOCUMENT_REQUEST',
}
