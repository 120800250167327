import CopyToClipboard from 'react-copy-to-clipboard';
import styled from 'styled-components';
import { IconButton } from '@billon/ui';

interface TextEllipsisProps {
  value: string;
}

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TextEllipsis = ({ value }: TextEllipsisProps) => {
  if (value.length < 15) {
    return <>{value}</>;
  }

  return (
    <StyledContainer>
      {`${value.slice(0, 4)}...${value.slice(-4)} `}
      <CopyToClipboard text={value}>
        {/* onClick is required by TS here. It is guaranteed by CopyToClipboard */}
        <IconButton icon="copy" regular onClick={() => {}} />
      </CopyToClipboard>
    </StyledContainer>
  );
};
