import { useMutation } from 'react-query';
import { AccountStatementFormatsEnum } from 'corporate-account-shared';

import { apiRoutes } from 'apiRoutes';
import { getRequest } from 'helpers';
import { generateReport } from 'utils/generateReport';

export const useDownloadTransactionDocumentRequest = (token?: string) =>
  useMutation(
    (id: number) =>
      getRequest(
        `${apiRoutes.TRANSACTION_DOCUMENT_DOWNLOAD.replace('{id}', `${id}`)}`,
        undefined,
        {
          ...(token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : undefined),
        },
      ),
    {
      onSuccess: (response: any) => {
        generateReport(
          response,
          response.title,
          AccountStatementFormatsEnum.PDF,
          false,
        );
      },
    },
  );
