import styled from 'styled-components';

export const RadioGroupOption = styled.div<{
  selected: boolean;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: ${({ theme, selected }) =>
    `2px solid ${selected ? theme.palette.primary : theme.palette.grayLight}`};
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 1rem;
  margin-top: 0.5rem;
  box-shadow: ${({ theme, selected }) =>
    selected
      ? `inset 0 0 0 2px ${theme.palette.primaryLight}`
      : theme.palette.grayLight};

  transition: ${({ theme }) => theme.transitionBase};

  h4 {
    margin: 0 0 0 1rem;
    font-weight: ${({ theme }) => theme.fontWeightBold};
  }
`;
