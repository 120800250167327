import {
  Icon,
  NavbarNav,
  NavbarNavItem,
  NavbarNavItemLink,
  ProtectedComponent,
  useAuthContext,
} from '@billon/ui';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { PermissionsEnum, UserTypesEnum } from 'corporate-account-shared';

// Utils
import { appRoutes } from 'appRoutes';

export const Menu = () => {
  const { user } = useAuthContext();

  return (
    <NavbarNav justify="center">
      {[UserTypesEnum.USER, UserTypesEnum.ADMIN].includes(user?.type) && (
        <NavbarNavItem
          renderProps={(props: any) => (
            <NavLink
              {...props}
              to={appRoutes.ACCOUNTS.replace('/:customerId?', '')}
              exact
              component={NavbarNavItemLink}
              iconSize="1.5x"
            />
          )}
        >
          <Icon name="home" />
          <FormattedMessage id="Accounts" defaultMessage="Accounts" />
        </NavbarNavItem>
      )}

      <ProtectedComponent resource={PermissionsEnum.CUSTOMER_MANAGE}>
        <NavbarNavItem
          renderProps={(props: any) => (
            <NavLink
              to={appRoutes.CUSTOMERS}
              {...props}
              component={NavbarNavItemLink}
              iconSize="1.5x"
            />
          )}
        >
          <Icon name="home" />
          <FormattedMessage id="Customers" defaultMessage="Customers" />
        </NavbarNavItem>
      </ProtectedComponent>

      <ProtectedComponent resource={PermissionsEnum.USER_MANAGE}>
        <NavbarNavItem
          renderProps={(props: any) => (
            <NavLink
              to={appRoutes.USERS.replace('/:customerId?', '')}
              {...props}
              component={NavbarNavItemLink}
            />
          )}
        >
          {user?.type === UserTypesEnum.SUPER_ADMIN ? (
            <FormattedMessage id="Users" defaultMessage="Users" />
          ) : (
            <FormattedMessage
              id="Customer's Users"
              defaultMessage="Customer's Users"
            />
          )}
        </NavbarNavItem>
      </ProtectedComponent>

      <ProtectedComponent resource={PermissionsEnum.CONSUMER_MANAGE}>
        <NavbarNavItem
          renderProps={(props: any) => (
            <NavLink
              to={appRoutes.CONSUMERS.replace('/:customerId?', '')}
              {...props}
              component={NavbarNavItemLink}
            />
          )}
        >
          <FormattedMessage
            id="Sub Accounts Module"
            defaultMessage="Sub Accounts Module"
          />
        </NavbarNavItem>
      </ProtectedComponent>

      <ProtectedComponent resource={PermissionsEnum.PAYOUT_MANAGE}>
        <NavbarNavItem
          renderProps={(props: any) => (
            <NavLink
              to={appRoutes.PAYOUT_HOME_PAGE}
              {...props}
              component={NavbarNavItemLink}
            />
          )}
        >
          <FormattedMessage id="Payouts" defaultMessage="Payouts" />
        </NavbarNavItem>
      </ProtectedComponent>

      <ProtectedComponent resource={PermissionsEnum.PAYIN_MANAGE}>
        <NavbarNavItem
          renderProps={(props: any) => (
            <NavLink
              to={appRoutes.PAYIN_HOME_PAGE}
              {...props}
              component={NavbarNavItemLink}
            />
          )}
        >
          <FormattedMessage id="Payins" defaultMessage="Payins" />
        </NavbarNavItem>
      </ProtectedComponent>

      <ProtectedComponent
        resource={PermissionsEnum.BANK_TRANSFER_BENEFICIARY_MANAGE}
      >
        <NavbarNavItem
          renderProps={(props: any) => (
            <NavLink
              to={appRoutes.BENEFICIARIES}
              {...props}
              component={NavbarNavItemLink}
            />
          )}
        >
          <FormattedMessage
            id="Recipients of bank transfers"
            defaultMessage="Recipients of bank transfers"
          />
        </NavbarNavItem>
      </ProtectedComponent>
      <ProtectedComponent
        resource={PermissionsEnum.PEER_TRANSFER_BENEFICIARY_MANAGE}
      >
        <NavbarNavItem
          renderProps={(props: any) => (
            <NavLink
              to={appRoutes.PEER_BENEFICIARIES}
              {...props}
              component={NavbarNavItemLink}
            />
          )}
        >
          <FormattedMessage
            id="Recipients of P2P transfers"
            defaultMessage="Recipients of P2P transfers"
          />
        </NavbarNavItem>
      </ProtectedComponent>
      <ProtectedComponent resource={PermissionsEnum.CUSTOMER_SETTINGS}>
        <NavbarNavItem
          renderProps={(props: any) => (
            <NavLink
              to={appRoutes.CUSTOMERS_SETTINGS}
              {...props}
              component={NavbarNavItemLink}
            />
          )}
        >
          <FormattedMessage
            id="Customer's Settings"
            defaultMessage="Customer's Settings"
          />
        </NavbarNavItem>
      </ProtectedComponent>
      <ProtectedComponent resource={PermissionsEnum.SETTINGS_MANAGE}>
        <NavbarNavItem
          renderProps={(props: any) => (
            <NavLink
              to={appRoutes.SETTINGS}
              {...props}
              component={NavbarNavItemLink}
            />
          )}
        >
          <FormattedMessage id="Settings" defaultMessage="Settings" />
        </NavbarNavItem>
      </ProtectedComponent>
    </NavbarNav>
  );
};
