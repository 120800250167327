import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Button } from '@billon/ui';

// Components
import { AccountFundsInfoModal } from 'components/AccountFundsInfoModal';

// Types
import { AccountInterface } from 'pages/Accounts/types';

export interface AccountFundsButtonProps {
  account: AccountInterface;
}

const AddFundsButton = styled(Button)`
  color: ${({ theme }) => theme.palette.infoDark};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  padding: 0.2rem 0;
  width: fit-content;
`;

export const AccountFundsButton = ({ account }: AccountFundsButtonProps) => {
  const [toggledFunds, setToggledFunds] = useState(false);

  const handleClickInfoButton = () => setToggledFunds(!toggledFunds);

  return (
    <>
      {!!account.topUpDetails?.length && (
        <AddFundsButton size="sm" onClick={handleClickInfoButton}>
          <FormattedMessage
            id="Fund E-Money Account"
            defaultMessage="Fund E-Money Account"
          />
        </AddFundsButton>
      )}

      <AccountFundsInfoModal
        isOpen={toggledFunds}
        toggle={handleClickInfoButton}
        account={account}
      />
    </>
  );
};
