import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Button, IconButton, Modal, ModalFooter } from '@billon/ui';
import CopyToClipboard from 'react-copy-to-clipboard';

// Types
import { AccountInterface } from 'pages/Accounts/types';

const StyledUl = styled.ul`
  background: ${({ theme }) => theme.palette.grayLightest};
  list-style: none;
  margin: 0;
  padding: 1rem;
  border-radius: ${({ theme }) => theme.borderRadius};
  transition: ${({ theme }) => theme.transitionBase};

  li {
    min-height: 2rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    word-wrap: break-word;
  }
`;

const StyledLabel = styled.label`
  font-weight: ${({ theme }) => theme.fontWeightBold};
  margin: 0;
  white-space: nowrap;
`;

export interface AccountFundsInfoProps {
  account: AccountInterface;
  isOpen: boolean;
  toggle: () => void;
}

export const AccountFundsInfoModal = ({
  account,
  isOpen,
  toggle,
}: AccountFundsInfoProps) => (
  <Modal
    isOpen={isOpen}
    title={
      <FormattedMessage
        id="Fund E-Money Account"
        defaultMessage="Fund E-Money Account"
      />
    }
    toggle={toggle}
  >
    <h5>
      <FormattedMessage
        id="In order to fund this E-Money Account please order a money transfer from your bank account with the following details"
        defaultMessage="In order to fund this E-Money Account please order a money transfer from your bank account with the following details"
      />
      :
    </h5>
    <div>
      {account.topUpDetails.map((topUpDetails) => (
        <StyledUl key={JSON.stringify(topUpDetails)}>
          {topUpDetails.bankName && (
            <li>
              <StyledLabel>
                <FormattedMessage id="Bank name" defaultMessage="Bank name" />:
              </StyledLabel>
              {topUpDetails.bankName}
            </li>
          )}
          {topUpDetails.bankAddress && (
            <li>
              <StyledLabel>
                <FormattedMessage
                  id="Bank address"
                  defaultMessage="Bank address"
                />
                :
              </StyledLabel>
              {topUpDetails.bankAddress}
            </li>
          )}
          {topUpDetails.beneficiaryName && (
            <li>
              <StyledLabel>
                <FormattedMessage
                  id="Account holder"
                  defaultMessage="Account holder"
                />
                :
              </StyledLabel>
              {topUpDetails.beneficiaryName}
            </li>
          )}
          {topUpDetails.iban && (
            <li>
              <StyledLabel>
                <FormattedMessage
                  id="Account number"
                  defaultMessage="Account number"
                />
                :
              </StyledLabel>
              {topUpDetails.iban}
              <CopyToClipboard text={topUpDetails.iban}>
                {/* onClick is required by TS here. It is guaranteed by CopyToClipboard */}
                <IconButton icon="copy" regular onClick={() => {}} />
              </CopyToClipboard>
            </li>
          )}
          {topUpDetails.BIC && (
            <li>
              <StyledLabel>
                <FormattedMessage
                  id="BIC / Swift"
                  defaultMessage="BIC / Swift"
                />
                :
              </StyledLabel>
              {topUpDetails.BIC}
              <CopyToClipboard text={topUpDetails.BIC}>
                {/* onClick is required by TS here. It is guaranteed by CopyToClipboard */}
                <IconButton icon="copy" regular onClick={() => {}} />
              </CopyToClipboard>
            </li>
          )}
          {topUpDetails.sortCode && (
            <li>
              <StyledLabel>
                <FormattedMessage id="Sort code" defaultMessage="Sort code" />:
              </StyledLabel>
              {topUpDetails.sortCode}
              <CopyToClipboard text={topUpDetails.sortCode}>
                {/* onClick is required by TS here. It is guaranteed by CopyToClipboard */}
                <IconButton icon="copy" regular onClick={() => {}} />
              </CopyToClipboard>
            </li>
          )}
          {topUpDetails.accountNumber && (
            <li>
              <StyledLabel>
                <FormattedMessage
                  id="Account number"
                  defaultMessage="Account number"
                />
                :
              </StyledLabel>
              {topUpDetails.accountNumber}
              <CopyToClipboard text={topUpDetails.accountNumber}>
                {/* onClick is required by TS here. It is guaranteed by CopyToClipboard */}
                <IconButton icon="copy" regular onClick={() => {}} />
              </CopyToClipboard>
            </li>
          )}
        </StyledUl>
      ))}
    </div>
    <ModalFooter>
      <Button color="primary" size="lg" onClick={toggle} block>
        <FormattedMessage id="Close" defaultMessage="Close" />
      </Button>
    </ModalFooter>
  </Modal>
);
