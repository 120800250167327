import { useQuery } from 'react-query';

import { apiRoutes } from 'apiRoutes';
import { QueryKeysEnum } from 'utils/constants';
import { getRequest } from 'helpers';

export const useAccountsRequest = (options: Record<string, any> = {}) => {
  return useQuery([QueryKeysEnum.ACCOUNTS_REQUEST, options], () =>
    getRequest(apiRoutes.ACCOUNT, options),
  );
};
