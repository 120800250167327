import { useIntl } from 'react-intl';
import { Col, Row } from 'react-awesome-styled-grid';
import { DateTime } from 'luxon';
import {
  Control,
  Controller,
  FieldErrors,
  FieldPath,
  UseFormWatch,
} from 'react-hook-form';

import {
  StandardFormField,
  DateInput,
  FormGroup,
  useLanguageContext,
  Select,
  FormFieldSize,
} from '@billon/ui';

// Utils
import { DateRangesEnum } from 'utils/constants';
import { dateRangeTypeFormatter } from 'formatters';

export interface DateRangeFilterPickerProps<T> {
  name?: FieldPath<T>;
  control: Control<T>;
  errors: FieldErrors<T>;
  watch: UseFormWatch<T>;
  label?: string;
  size: string;
  clearable?: boolean;
  isLoading?: boolean;
  enableTime?: boolean;
  dateFormat?: string;
  options?: DateRangesEnum[];
}

export function DateRangeFilterPicker<T>({
  name = 'date' as FieldPath<T>,
  label = 'Date',
  control,
  errors,
  size,
  watch,
  isLoading = false,
  clearable = false,
  enableTime = false,
  dateFormat,
  options = Object.keys(DateRangesEnum) as DateRangesEnum[],
}: DateRangeFilterPickerProps<T>) {
  const { formatMessage } = useIntl();
  const { language } = useLanguageContext();

  const watchDateRange = watch(`${name}Range` as FieldPath<T>);
  const watchDate = watch(name) as
    | {
        from: Date;
        to: Date;
      }
    | undefined;

  return (
    <div>
      <FormGroup>
        <Controller
          name={`${name}Range` as FieldPath<T>}
          render={({ field }) => (
            <Select
              {...field}
              label={formatMessage({ id: label, defaultMessage: label })}
              placeholder={formatMessage({
                id: `Select ${label.toLocaleLowerCase()}`,
                defaultMessage: `Select ${label.toLocaleLowerCase()}`,
              })}
              errors={errors}
              options={[
                {
                  value: '',
                  label: '',
                  invisible: true,
                },
                ...options.map((p) => ({
                  value: p,
                  label: dateRangeTypeFormatter(p, formatMessage),
                })),
              ]}
              disabled={isLoading}
              size={size as FormFieldSize}
              clearable={clearable}
            />
          )}
          control={control}
        />

        {watchDateRange === DateRangesEnum.ANOTHER_PERIOD && (
          <Row>
            <Col sm={4}>
              <StandardFormField<T>
                name={`${name}.from` as FieldPath<T>}
                control={control}
                errors={errors}
                size={size}
                renderComponent={DateInput}
                placeholder={formatMessage({
                  id: 'From',
                  defaultMessage: 'From',
                })}
                defaultHour={0}
                defaultMinute={0}
                defaultSeconds={0}
                maxDate={watchDate?.to || DateTime.utc().toJSDate()}
                locale={language}
                isLoading={isLoading}
                clearable={clearable}
                enableTime={enableTime}
                dateFormat={dateFormat}
                label={formatMessage({
                  id: 'From',
                  defaultMessage: 'From',
                })}
              />
            </Col>
            <Col sm={4}>
              <StandardFormField<T>
                name={`${name}.to` as FieldPath<T>}
                control={control}
                errors={errors}
                size={size}
                renderComponent={DateInput}
                placeholder={formatMessage({
                  id: 'To',
                  defaultMessage: 'To',
                })}
                defaultHour={23}
                defaultMinute={59}
                defaultSeconds={59}
                minDate={
                  watchDate?.from
                    ? DateTime.fromJSDate(watchDate.from).endOf('day').toISO()
                    : undefined
                }
                locale={language}
                isLoading={isLoading}
                clearable={clearable}
                enableTime={enableTime}
                dateFormat={dateFormat}
                label={formatMessage({
                  id: 'To',
                  defaultMessage: 'To',
                })}
              />
            </Col>
          </Row>
        )}
      </FormGroup>
    </div>
  );
}
