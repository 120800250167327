import { FormattedMessage } from 'react-intl';

export interface AccountNameProps {
  id?: number;
  name?: string;
}

export const AccountName = ({ id, name }: AccountNameProps) => (
  <>
    {name || (
      <FormattedMessage
        id="Digital cash Account"
        defaultMessage="Digital cash Account"
      />
    )}
    {id && ` (#${id})`}
  </>
);
