import { useState } from 'react';
import { CurrencyCodesEnum, milibillonToCurrencyValue } from '@billon/utils';
import { useAuthContext } from '@billon/ui';

// Hooks
import { useAccountsRequest } from 'pages/Accounts/hooks';

// Types
import { AccountInterface } from 'pages/Accounts/types';

// Utils
import { verifyUserCanManageAccount } from 'utils/verifyUserCanManageAccount';

export interface UserAccountsOption {
  value: number;
  balance: number;
  currency: CurrencyCodesEnum;
  disabled: boolean;
  name?: string;
}

export const useSelectedAccount = () => {
  const { user } = useAuthContext();

  const [selectedAccount, setSelectedAccountOption] =
    useState<UserAccountsOption | null>(null);

  const { data, isFetching } = useAccountsRequest({
    where: {
      customerId: user.customerId,
    },
  });

  const accounts = (data || []).reduce(
    (prev: AccountInterface[], curr: AccountInterface) => {
      if (verifyUserCanManageAccount(user, curr)) {
        return [
          ...prev,
          {
            value: curr.id,
            balance: milibillonToCurrencyValue(curr.balance),
            currency: curr.currency,
            disabled: curr.balance === 0,
            name: curr.name,
          },
        ];
      }

      return prev;
    },
    [],
  );

  const setSelectedAccount = (accountId: number) =>
    setSelectedAccountOption(
      accounts.find(
        (userAccount: UserAccountsOption) => userAccount.value === accountId,
      ),
    );

  return {
    accounts,
    selectedAccount,
    setSelectedAccount,
    isFetching,
  };
};
