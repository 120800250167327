import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  Navbar,
  BillonLogo,
  useAuthContext,
  ChangeLanguage,
  MyProfileModal,
  DefaultMyProfileForm,
} from '@billon/ui';

// Utils
import { apiRoutes } from 'apiRoutes';
import { appRoutes } from 'appRoutes';

// Components
import { CustomerLogo } from 'components/CustomerLogo';
import { LoggedInUserInfo, Menu } from './components';

// Formatters
import { userTypeFormatter } from 'formatters';

export const Header = () => {
  const { user } = useAuthContext();
  const { formatMessage } = useIntl();

  const [isMyProfileModalOpen, setMyProfileModalOpen] =
    useState<boolean>(false);

  const handleOpenMyProfileModal = (closeDropdown: () => void) => {
    setMyProfileModalOpen(true);
    closeDropdown();
  };

  return (
    <Navbar fluid>
      <Link to={appRoutes.HOME_PAGE_PANEL}>
        {user?.customer?.logoUrl ? (
          <CustomerLogo src={user.customer.logoUrl} maxHeight="4rem" />
        ) : (
          <BillonLogo />
        )}
      </Link>
      <Menu />
      <ChangeLanguage />
      <LoggedInUserInfo onOpenMyProfileModal={handleOpenMyProfileModal} />
      <MyProfileModal
        isOpen={isMyProfileModalOpen}
        toggle={() => setMyProfileModalOpen(!isMyProfileModalOpen)}
        meUrl={apiRoutes.ME}
        myProfileUrl={apiRoutes.MY_PROFILE}
        formComponent={(props) => <DefaultMyProfileForm {...props} />}
        userType={userTypeFormatter(user.type, formatMessage)}
      />
    </Navbar>
  );
};
