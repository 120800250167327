import { hasPermission } from '@billon/utils';
import {
  RepresentativePermissionsEnum,
  UserTypesEnum,
} from 'corporate-account-shared';
import { AccountInterface } from 'pages/Accounts/types';

export function verifyUserCanManageAccount<T extends AccountInterface>(
  user: any,
  account: T,
) {
  if (user.type !== UserTypesEnum.USER || account.userId === user.id) {
    return true;
  }

  let representatives = account.representatives;

  if (!Array.isArray(representatives)) {
    representatives = [representatives];
  }

  return representatives.some((representative) => {
    if (representative.userId === user.id) {
      return hasPermission(representative.permissions, [
        RepresentativePermissionsEnum.ACCOUNT_MANAGE,
      ]);
    }
  });
}
