import config from './config';

export const apiRoutes = {
  ROOT: config.backendUrl,
  STATUS: `${config.backendUrl}/status`,
  ME: `${config.backendUrl}/me`,
  MY_PROFILE: `${config.backendUrl}/myprofile`,
  LOGIN: `${config.backendUrl}/login`,
  LOGIN_AUTHORIZE: `${config.backendUrl}/login/authorize`,
  ACCOUNT: `${config.backendUrl}/account`,
  ACCOUNT_STATEMENT: `${config.backendUrl}/account/{accountId}/statement`,
  SINGLE_ACCOUNT: `${config.backendUrl}/account/{id}`,
  ACCOUNT_TOGGLE_ACTIVATION: `${config.backendUrl}/account/toggleActivation`,
  ACCOUNT_CHANGE_NAME: `${config.backendUrl}/account/changeName`,
  ACCOUNT_CHANGE_OWNERSHIP: `${config.backendUrl}/account/changeOwnership`,
  ACCOUNT_REPRESENTATIVE: `${config.backendUrl}/account/representative`,
  USER: `${config.backendUrl}/user`,
  SINGLE_USER: `${config.backendUrl}/user/{id}`,
  USER_RESTORE_PASSWORD: `${config.backendUrl}/user/restorePassword`,
  SETTINGS: `${config.backendUrl}/settings`,
  TRANSACTION: `${config.backendUrl}/transaction`,
  TRANSACTION_DOCUMENT: `${config.backendUrl}/transaction/{id}/document`,
  TRANSACTION_DOCUMENT_DOWNLOAD: `${config.backendUrl}/transaction/{id}/document/download`,
  PAYMENT_ROUTES_REQUIRED_FIELDS: `${config.backendUrl}/paymentRoutes/requiredFields`,
  PAYMENT_ROUTES_COUNTRIES: `${config.backendUrl}/paymentRoutes/countries`,

  PAYOUT: `${config.backendUrl}/payout`,
  SINGLE_PAYOUT: `${config.backendUrl}/payout/{id}`,
  PAYOUT_STATUS: `${config.backendUrl}/payout/status`,
  PAYOUT_FROM_FILE: `${config.backendUrl}/payout/fromFile`,
  PAYOUT_RECIPIENT: `${config.backendUrl}/payout/recipient`,
  PAYOUT_SINGLE_RECIPIENT: `${config.backendUrl}/payout/recipient/{id}`,
  PAYOUT_RECIPIENT_FROM_FILE: `${config.backendUrl}/payout/recipient/fromFile`,
  PAYOUT_RECIPIENT_LOGIN: `${config.backendUrl}/payout/recipient/login`,
  PAYOUT_RECIPIENT_AUTHENTICATE: `${config.backendUrl}/payout/recipient/authenticate`,
  PAYOUT_RECIPIENT_TOGGLE_BLACK_LISTED: `${config.backendUrl}/payout/recipient/toggleBlackListed`,
  PAYOUT_PROJECT: `${config.backendUrl}/payout/project`,
  PAYOUT_SINGLE_PROJECT: `${config.backendUrl}/payout/project/{id}`,
  PAYOUT_COLLECTION: `${config.backendUrl}/payout/collection`,
  PAYOUT_COLLECTION_CHECK_LIMITS: `${config.backendUrl}/payout/collection/checkLimits`,
  PAYOUT_COLLECTION_BANK_ACCOUNT: `${config.backendUrl}/payout/collection/bankAccount`,
  PAYOUT_COLLECTION_SETTINGS: `${config.backendUrl}/payout/collection/settings`,
  PAYOUT_COLLECTION_TEMPLATE_PROPERTIES: `${config.backendUrl}/payout/collection/templateProperties`,
  PAYOUT_COLLECTION_URLS: `${config.backendUrl}/payout/collection/urls`,
  PAYOUT_GENERATE_REPORT: `${config.backendUrl}/payout/report`,

  PAYIN: `${config.backendUrl}/payin`,
  PAYIN_BARCODE: `${config.backendUrl}/payin/barcode`,
  PAYIN_BARCODE_GENERATE_REPORT: `${config.backendUrl}/payin/barcode/report`,
  PAYIN_BARCODE_RENDER: `${config.backendUrl}/payin/barcode/render/:barcode`,
  PAYIN_PAYER: `${config.backendUrl}/payin/payer`,
  PAYIN_SINGLE_PAYER: `${config.backendUrl}/payin/payer/{id}`,
  PAYIN_PAYER_FROM_FILE: `${config.backendUrl}/payin/payer/fromFile`,
  PAYIN_PAYER_TOGGLE_BLACK_LISTED: `${config.backendUrl}/payin/payer/toggleBlackListed`,

  COMMISSION: `${config.backendUrl}/commission`,
  SINGLE_COMMISSION: `${config.backendUrl}/commission/{id}`,
  COUNTRY: `${config.backendUrl}/country`,

  CUSTOMER: `${config.backendUrl}/customer`,
  SINGLE_CUSTOMER: `${config.backendUrl}/customer/{id}`,
  CUSTOMER_SETTINGS: `${config.backendUrl}/customer/settings`,
  CUSTOMER_SETTINGS_MODULES_SETTINGS: `${config.backendUrl}/customer/settings/{customerId}/modules`,
  CUSTOMER_SETTINGS_REDEEM_PAYMENT_DETAILS: `${config.backendUrl}/customer/settings/{customerId}/redeemDetails`,

  BANK_TRANSFER_BENEFICIARY: `${config.backendUrl}/bankTransfer/beneficiary`,
  BANK_TRANSFER_SINGLE_BENEFICIARY: `${config.backendUrl}/bankTransfer/beneficiary/{id}`,
  BANK_TRANSFER: `${config.backendUrl}/bankTransfer`,

  BANK_TRANSFER_REDEEM: `${config.backendUrl}/redeem`,

  PEER_TRANSFER: `${config.backendUrl}/peerTransfer`,
  PEER_TRANSFER_BENEFICIARY: `${config.backendUrl}/peerTransfer/beneficiary`,
  PEER_TRANSFER_SINGLE_BENEFICIARY: `${config.backendUrl}/peerTransfer/beneficiary/{id}`,

  CONSUMER: `${config.backendUrl}/consumer`,
  SINGLE_CONSUMER: `${config.backendUrl}/consumer/{id}`,
};
