import { StandardFormField } from '@billon/ui';
import { useIntl } from 'react-intl';
import { FieldPath } from 'react-hook-form';

// Types
import { TransactionFieldProps } from '../types';

export function BeneficiaryName<T>({
  errors,
  control,
  isLoading,
  disabled = false,
  name = 'beneficiaryName',
  namePrefix = '',
  placeholder = 'Place beneficiary name here',
  label = 'Beneficiary name',
  required,
}: TransactionFieldProps<T>) {
  const { formatMessage } = useIntl();
  return (
    <StandardFormField<T>
      control={control}
      isLoading={isLoading}
      disabled={disabled}
      errors={errors}
      name={`${namePrefix}${name}` as FieldPath<T>}
      placeholder={formatMessage({
        id: placeholder,
        defaultMessage: placeholder,
      })}
      label={formatMessage({
        id: label,
        defaultMessage: label,
      })}
      required={required}
    />
  );
}
