import { DateTime } from 'luxon';
import download from 'downloadjs';
import { AccountStatementFormatsEnum } from 'corporate-account-shared';

type GenerateReportResponse = {
  fileBuffer: {
    type: string;
    data: number[];
  };
};

export const generateReport = (
  response: GenerateReportResponse,
  name: string,
  extension: 'xlsx' | AccountStatementFormatsEnum,
  withDate = true,
) => {
  const dateNow = DateTime.local();
  const fileName = `${name}${
    withDate ? `_${dateNow.toFormat('dd-MM-yyyy-HH-mm-ss')}` : ''
  }.${extension.toLowerCase()}`;
  const blob = new Blob([new Uint8Array(response.fileBuffer.data)]);
  download(blob, fileName);
};
